<template>
    <div class="relative flex flex-col border border-2 border-gray-300 bg-white rounded-lg p-5 w-full cursor-pointer hover:border-sky-300">
      <!-- Ribbon -->
      <div class="absolute top-0 right-0">
        <ContentLoader :speed="2" :width="80" :height="20">
          <rect x="0" y="0" rx="4" ry="4" width="80" height="20" />
        </ContentLoader>
      </div>
  
      <!-- Job Title -->
      <div class="mb-3">
        <ContentLoader :speed="2" :width="150" :height="25">
          <rect x="0" y="0" rx="4" ry="4" width="150" height="25" />
        </ContentLoader>
      </div>
  
      <!-- Company Name -->
      <div class="mb-3">
        <ContentLoader :speed="2" :width="120" :height="20">
          <rect x="0" y="0" rx="4" ry="4" width="120" height="20" />
        </ContentLoader>
      </div>
  
      <!-- Location and Rate -->
      <div class="flex justify-between mt-3 mb-3">
        <ContentLoader :speed="2" :width="100" :height="20">
          <rect x="0" y="0" rx="4" ry="4" width="100" height="20" />
        </ContentLoader>
        <ContentLoader :speed="2" :width="100" :height="20">
          <rect x="0" y="0" rx="4" ry="4" width="100" height="20" />
        </ContentLoader>
      </div>
  
      <!-- Industry and Role -->
      <div class="flex justify-between mb-3">
        <ContentLoader :speed="2" :width="150" :height="20">
          <rect x="0" y="0" rx="4" ry="4" width="150" height="20" />
        </ContentLoader>
        <ContentLoader :speed="2" :width="100" :height="20">
          <rect x="0" y="0" rx="4" ry="4" width="100" height="20" />
        </ContentLoader>
      </div>
  
      <!-- Dates -->
      <div class="flex justify-between items-center">
        <ContentLoader :speed="2" :width="100" :height="15">
          <rect x="0" y="0" rx="4" ry="4" width="100" height="15" />
        </ContentLoader>
        <ContentLoader :speed="2" :width="100" :height="15">
          <rect x="0" y="0" rx="4" ry="4" width="100" height="15" />
        </ContentLoader>
      </div>
    </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
    name: 'JobCardLoading',
    components: {
        ContentLoader,
    },
}
</script>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import Vuex from "vuex";
// import { createStore } from 'vuex'
import store from './store'
import './assets/tailwind.css'

// Create a new store instance.
// const store = createStore({
//     state () {
//       return {
//         count: 0,
//         isLoggedIn: false,
//       }
//     },
//     mutations: {
//       increment (state) {
//         state.count++
//       }
//     }
//   })

// createApp(App).use(router).mount('#app')
const app = createApp(App)
app.use(router)
app.use(store)
// Custom filters
app.config.globalProperties.$filters = {
    initials(value) {
        if (value == undefined) {
            return 'N/A'
        }

        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
        let initials = [...value.matchAll(rgx)] || []
        initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase()

        return initials
    },
    screenedYesNo(value) {
        if (value == 'N') {
            return 'No'
        } else if (value == 'Y') {
            return 'Yes'
        }
    },
    screenedDate(m) {
        // If not currently employed return N/A
        if (m == null) {
            return 'N/A'
        } else {
            if (m < 12) {
                return m + ' months'
            } else {
                let years = Math.floor(m/12);
                let months = m % 12;

                if (months == 0) {
                    return years == 1 ? years + ' year' : years + ' years'
                } else {
                    let yrs_str = years == 1 ? years + ' year' : years + ' years'
                    let mnt_str = months == 1 ? months + ' month' : months + ' months'

                    return yrs_str + ' ' + mnt_str
                }
                // // Split months in years
                // let my = m / 12
                // // return 'X years ' + m + 'months'
                // return ((my % 1 !== 0) ? my.toFixed(1) : my) + ' years'
            }
        }
    },
    screenedCompanies(value) {
        try {
            let x = JSON.parse(value);
            return x.join(', '); // If parsing is successful, the JSON is valid
          } catch (error) {
            return value; // If parsing fails, the JSON is invalid
          }
    },
    screenedSalary(s) {
        if (s == null || s == 0) {
            return 'Not defined'
        }

        return '$' + s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    screenedRate(s) {
        if (s == null || s == 0) {
            return 'Not defined'
        }

        return '$' + s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    screenedEmail(s) {
        if (s == null || s == '') {
            return 'Not defined'
        } else {
            return s
        }
    },
    screenedPhone(s) {
        if (s == null || s == '') {
            return 'Not defined'
        } else {
            return s
        }
    },
    screenedLinkedin(s) {
        if (s == null || s == '') {
            return 'Not defined'
        } else {
            return s
        }
    },
    screenedVisa(s) {
        if (s == null || s == '') {
            return 'Not defined'
        } else {
            return s
        }
    },
    screenedLocation(s) {
        if (s == null || s == '') {
            return 'Not defined'
        } else {
            return s
        }
    },
    screenedEmployeeType(v) {
        if (v == 0) {
            return 'Permanent'
        } else if (v == 1) {
            return 'Contract'
        } else if (v == 2) {
            return 'Both'
        }
    },
    screenedAvailability(a) {
        if (a == null) {
            return 'Not provided'
        }

        if (a == 0.1) {
            return 'From now'
        } else if (a == 0.2) {
            return 'In one week'
        } else if (a == 0.5) {
            return 'In two weeks'
        } else if (a == 1) {
            return 'In one month'
        } else {
            var timestampDate = new Date(a * 1000);
            const date = new Date(timestampDate.getFullYear(), timestampDate.getMonth(), timestampDate.getDate())
            const result = date.toLocaleDateString("en-GB", { // you can use undefined as first argument
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            })
            return result;
        }
    },
    screenedWorkArrangement(s) {
        if (s == 'office') {
            return 'Office / On Site'
        } else if (s == 'wfh') {
            return 'WFH'
        } else {
            if (s) {
                return s.replace(/_/g, ' ').replace(/^./, (char) => char.toUpperCase());
            }
        }
    },
    statsAmount(s) {
        if (s === null) {
            return 'Not applicable'
        } else if (s === 'c') { // Show nothing while calculating. can't use null because null will also b e returned from db
            return ''
        }

        return '$' + s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    statsPeriod(m) {
        if (m === null) {
            return 'Not application'
        } else if (m === 'c') { // don't show anything while calculating. can't show null because null is also returned from db
            return ''
        }

        let period = m / 12

        return Math.round(period * 10 ) / 10 + ' years'
    }
}



app.mount('#app')